const resume = {
    "header": {
        "name": "Benjamin Protus",
        "location": "Los Angeles",
        "currentRole": "Full-Stack Developer and Software Engineer",
        "roleDescription": "I create dynamic web applications, maintain complex backends, and enjoy chai tea and skiing.",
    },

    "socialLinks": [
        {
            "name": "Email",
            "url": "contact@benprotus.com",
            "icon": "fa fa-envelope",
            "prefix": "mailto:"
        },
        {
            "name": "LinkedIn",
            "url": "linkedin.com/in/BenProtus",
            "icon": "fa fa-linkedin",
            "prefix": "https://www."
        },
        {
            "name": "GitHub",
            "url": "github.com/BenProtus",
            "icon": "fa fa-github",
            "prefix": "https://"
        }
    ],

    "about": {
        "summary": `I'm a recent graduate with over a year of professional full-stack software development experience. 
            I'm an outgoing, detail-oriented developer who thrives in a collaborative environment and I communicate my 
            ideas and questions effectively to produce high-quality results.`,

        "university": {
            "name": "Loyola Marymount University",
            "location": "Los Angeles, CA",
            "dateStarted": "August 2015",
            "dateGraduated": "May 2019",
            "degree": "B.S. in Computer Science",
            "summary": `When I wasn't creating new technologies in the lab, I explored the theatrical arts from the
                lens of a writer, director, and actor to receive a minor in Theater. I also founded and led LMU's
                EDM club, involving organizing charity events promoting the organization's talented DJs and
                coordinating a partnership with Insomniac Events.`,
        },
    },

    "portfolio": [
        {
            "title": "HP Sustainability Bond Framework",
            "summary": `Led a team of engineers in the development a sustainability framework that provides reporting
                and analytics about offsetting emissions to reach carbon neutrality. Worked directly with
                HP directors to architect and program solutions that meet their needs using .NET (C#), JavaScript,
                and Microsoft SQL Server.`,
            "url": "https://press.hp.com/us/en/blogs/2021/hp-sustainable-bond-framework-and-pricing.html",
            "img": "/images/logos/hp.jpg"
        },
        {
            "title": "TechEmpower Intranet",
            "summary": `Created new tools on the company-wide internal network for company members to increase
                productivity, implemented using the Java framework Gemini and SQL Server for backend development and
                JavaScript, HTML, CSS/SASS, and JavaScript Pages (JSP) for the client-side application.`,
            "url": "",
            "img": "/images/logos/techempower.jpg"
        },
        {
            "title": "Tinco Website Redesign",
            "summary": `Led the company's website redesign, organizing development efforts and company resources to
                create a unique customer experience that successfully draws in more potential clients, with website
                traffic increasing by 1000%.`,
            "url": "https://tincosheetmetal.com",
            "img": "/images/logos/tinco.jpg"
        },
        /*{
            "title": "Nebula VR",
            "summary": `A Virtual Reality programming language designed for the 3D space. Nebula VR
                is asynchronous, object-oriented, dynamically-typed, and spatially-scoped. Created dynamic 3D menus
                and nodes in Unity that interface with the underlying language mechanics to produce functions in VR.`,
            "url": "https://nebulavr.github.io/",
            "img": "/images/logos/nebulavr.jpg"
        },*/
        {
            "title": "Microsoft HoloLens Art Gallery",
            "summary": `Programmed an Augmented-Reality (AR) art gallery application for the Microsoft HoloLens that
                allows the user to position, change, and rearrange artwork around any room. Independently developed
                the Unity program using C# and the Vuforia library.`,
            "url": "https://github.com/BenProtus/HoloLensArtGallery",
            "img": "/images/logos/hololens.jpg"
        },
        {
            "title": "Pycante",
            "summary": `The world's HOTTEST programming language! Designed this programming language as a learning
                tool for others to start learning the fundamentals of programming. Modeled off of Python, the
                language compiles to JavaScript and uses fun language and icons to engage new users.`,
            "url": "https://github.com/BenProtus/Pycante",
            "img": "/images/logos/pycante.jpg"
        }
    ],

    "testimonials": [
        {
            "name": "Keith Gustafson",
            "company": "TechEmpower",
            "title": "VP of Development",
            "quote": `Ben made significant contributions to internal applications. With every task he’s given, Ben
                applies his usual enthusiasm and thoughtfulness. We can always be confident that he either knows
                what he should be doing or he will ask appropriate questions to get clarification. He always has a
                positive attitude and makes the office a more fun place to be.`
        },
        {
            "name": "Brian Powell",
            "company": "Tinco Sheet Metal",
            "title": "CEO",
            "quote": `Ben's work on our website and internet marketing has made a significant difference to our
                business. Ben demonstrated his leadership skills by working closely with the various department heads
                to capture critical content while maintaining site design continuity. His ability to work efficiently
                under tight deadlines speaks volumes of his composure and calm demeanor.`
        },
        {
            "name": "Prabish Chandran",
            "company": "VidyaTech",
            "title": "VP of Engineering",
            "quote": `Mr. Benjamin Protus is a talented software engineer. His remarkable ability to troubleshoot
              problems and come up with solutions or workarounds quickly makes him an invaluable asset to any
              technology team. I will wholeheartedly recommend Ben for any role that requires the candidate to be
              innovative, independent, responsible, and result oriented.`
        }
    ],

    "contact": {
        "concludingStatements": [
            "Want to work with me? Awesome!",
            "You can reach me via email or LinkedIn.",
            "You can also check out my projects on GitHub.",
            "Cheers!"
        ]
    }
};

export default resume;